<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" v-b-modal.new-modal>ثبت مدیر</b-button>
    <b-card title="مدیرها" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="title">اسم</label>
                  <b-form-input id="title" v-model="searchParams.name"></b-form-input>
                </div>
              </div>

              <div class="col-md-6">
                <b-form-group label="وضعیت" label-for="status">
                  <b-form-select id="status" v-model="searchParams.status">
                    <b-form-select-option value="1">فعال</b-form-select-option>
                    <b-form-select-option value="0">غیرفعال</b-form-select-option>
                    <b-form-select-option value="">همه</b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">
        <template v-slot:cell(created_at)="data">{{ data.item.created_at | persianDate }}</template>
        <template
          v-slot:cell(last_login)="data">
          <span v-if="data.item.last_login">{{ data.item.last_login | persianDate }}</span>
        </template>
        <template v-slot:cell(roles)="data">
          <span v-for="(role) in data.item.roles" class="mr-2 badge badge-info" :key="role.id">{{ role.name }}</span>
        </template>
        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>

        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)">
            <i class="fa fa-edit"></i>
          </button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)">
            <i class="fa fa-close"></i>
          </button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>

    <b-modal id="new-modal" title="ثبت مدیر" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">
          <b-form-group>
            <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="name" name="name" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="email"> ایمیل <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="email" name="email" type="email" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="mobile"> موبایل <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="mobile" name="mobile" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="password"> رمز عبور <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="password" name="password" type="password" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="password_confirmation"> تکرار رمز عبور <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="password_confirmation" name="password_confirmation" type="password" data-required>
            </b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="status"> نقش ها </label>
            <multiselect :close-on-select="false" :clear-on-select="false" deselectLabel selectLabel selectedLabel
              v-model="selected_roles" :options="roles" :multiple="true" label="name" track-by="id" placeholder>
            </multiselect>
            <div v-for="item in selected_roles" :key="item.id">
              <input type="hidden" name="roles[]" :value="item.name">
            </div>
            <input type="hidden" v-if="selected_roles.length == 0">
          </b-form-group>
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش مدیر" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <b-form-group>
            <label for="name"> اسم <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="name" name="name" v-model="edit.name" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="email"> ایمیل <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="email" name="email" type="email" v-model="edit.email" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="mobile"> موبایل <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="mobile" name="mobile" v-model="edit.mobile" data-required></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="password"> رمز عبور <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="password" name="password" type="password"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="password_confirmation"> تکرار رمز عبور <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <b-form-input id="password_confirmation" name="password_confirmation" type="password"></b-form-input>
          </b-form-group>

          <b-form-group>
            <label for="status"> نقش ها </label>
            <multiselect :close-on-select="false" :clear-on-select="false" deselectLabel selectLabel selectedLabel
              v-model="edit.roles" :options="roles" :multiple="true" label="name" track-by="id" placeholder>
            </multiselect>
            <div v-for="item in edit.roles" :key="item.id">
              <input type="hidden" name="roles[]" :value="item.name">
            </div>
            <input type="hidden" v-if="edit.roles.length == 0">
          </b-form-group>
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="PUT" name="_method">
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/admins',
        title: 'مدیرها',
        selected_roles: [],
        selected_filter_status: '',
        roles: [],
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'name',
            label: 'اسم'
          },
          {
            key: 'mobile',
            label: 'موبایل'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'roles',
            label: 'نقش‌ها'
          },
          {
            key: 'last_login',
            label: 'آخرین ورود'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          // { key: 'longitude', label: 'عرض جغرافیایی' },
          // { key: 'latitude', label: 'طول جغرافیایی' },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ],
        selected_status: '1'
      }
    },
    methods: {
      loadRoles() {
        this.$axios.get(this.$root.baseUrl + '/api/admin/roles')
          .then(response => {
            this.roles = response.data.data
          })
      },

    },
    created() {
      this.loadItems()
      this.loadRoles()
      this.searchParams = {
        name: '',
        status: '',
      }
    }
  }
</script>